import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
//import { useRecoilState } from "recoil";
//import {darkModeState} from './state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChalkboard, faChalkboardTeacher,faLightbulb, faLaptopCode, faUserNinja, faUsersCog, faUserTie } from '@fortawesome/free-solid-svg-icons'
import { faReadme, faStackOverflow } from "@fortawesome/free-brands-svg-icons"

const WhyMeBlock = ({icon, title, text}) => (<div className="why-me-box">
<div className="box-inner">
  <div className="icon"><FontAwesomeIcon icon={icon}/></div>
  <div className="content">
    <div className="title">{title}</div>
    <div className="text">{text}</div>
  </div>
</div>
</div>);

const WhyMe = ({darkMode}) => {
 // let [darkMode, setDarkMode] = useRecoilState(darkModeState);
  let whyMes = [
    {
      id: 1,
      icon: faUserNinja,
      title: "Jack of All Trades",
      text: "I focus on the front-end development but my experience includes various full-stack and back-end projects as well as some experience with related areas (UI/UX, QA, DevOps, SEO). I have a good understanding of all steps in the software development life cycle."
    },
    {
      id: 2,
      icon: faUsersCog,
      title: "Excellent Communicator",
      text: "I am constantly communicating with clients, developers and designers via emails and video calls. I can discuss complicated solutions with fellow engineers and easily break them down in simple terms for non-technical people."
    },
    {
      id: 3,
      icon: faLaptopCode,
      title: "Self-managing Problem Solver",
      text: "I like to take owership of the problems and find solutions. I am a team player but I can work independently and came up with suggestions for the team."
    },
    {
      id: 4,
      icon: faLightbulb,
      title: "Open-minded Fast Learner",
      text: "As a self-taught programmer and I'm always eager to learn more and improve my skills. I can easily learn new tools and framework, understand other people's code and make changes as necessary."
    },
  ]
  return (<section className="section why-me bg-primary" id="why-me" >
  <div className="container">
    <div className="why-me-inner section-inner">
      <div className="why-me-left section-left">
        
        <p className="section-title">Why Me</p>
        <p className="text">
          Why you should hire me into your software engineering team:
        </p>
      </div>
      <div className="why-me-boxes">
        {whyMes.map(item => <WhyMeBlock key={item.id} {...item}/>)}
      </div>
    </div>
  </div>
</section>
)}


export default WhyMe
