import React, {useEffect, useRef, useState} from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TypingText from "../components/typing-text"
import "../sass/main.scss";
// import { useRecoilState } from "recoil"
// import {darkModeState} from '../components/state';
import AboutMe from "../components/about-me"
import Portfolio from "../components/portfolio"
import MySkills from "../components/my-skills"
import WhyMe from "../components/why-me"

import jacksHeart from '../gifs/jack.gif'
import knocks from '../gifs/knocks.gif'
import father from '../gifs/your-father.gif'
import Contact from "../components/contact"
// import { RecoilRoot } from 'recoil'
// import { Scrollchor, linear } from 'react-scrollchor';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Scrollchor, easeOutQuad } from 'react-scrollchor';


const IndexPage = () => {
  const heroInner = useRef(null);
  const subtitle = useRef(null);
  const [offset, setOffset] = useState(0);
  const [scrOffset, setScrOffset] = useState(700);
  const [darkMode, setDarkMode] = useState(false);

  const basis = 2400; 
 // const [basis, setBasis] = useState(1600);
  const handleScroll = (e) => {
    const scrollTop =
    document.body.scrollTop || document.documentElement.scrollTop;
    let offset = Math.min(scrollTop, 1200);
    setOffset(offset);
  }
    useEffect(() => {
    setScrOffset(window.innerHeight*1.2 - 20);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  //  setBasis(window.innerHeight);
  },[]);


 
  return (<Layout darkMode={darkMode} setDarkMode={setDarkMode}>
    <Seo title="Full-Stack Software Engineer" description="I am a Software Engineer with more than 8 years of working experience." />
    <div className="" style={{marginTop:"1200px"}}></div>

    <section className={"section hero" + (offset<1200?" sticky":"") + (darkMode?" bg-dark dark-mode":"")} id="hero" >
      <div ref={heroInner} className="container" style={{transition: "linear .3s transform", height: "100vh", transform: `scale(${Math.floor((basis-offset)/basis*20)/20})`, transformOrigin: `center`, willChange:"transform"}}> 
        <div style={{opacity: Math.min(offset*4/basis,1)}}>
          <StaticImage
                src="../images/laptop.png"
                width={3000}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Laptop"
                className="laptop"
                transformOptions = {{fit: "cover"}}
              />
          <StaticImage
                src="../images/phone.png"
                width={1200}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Mobile"
                className="mobile"
                transformOptions = {{fit: "cover"}}
              />
          </div>
          <div className={"devtools "+(offset>600?"active":"")}  >
          <StaticImage
                src="../images/devtools.png"
                width={900}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Dev Tools"
                className="devtools-img"
                transformOptions = {{fit: "cover"}}
              />

          </div>

        <div className="hero-inner">

          <div className="hero-left"> 
            <div ref={subtitle} className={"subtitle "+(offset>500?"active":"")}><div className="tooltip"><span className="elem">div</span><span className="class">.subtitle</span><span className="dims">{(subtitle && subtitle.current) ? `${subtitle.current.clientWidth} x ${subtitle.current.clientHeight}` : "460 x 36"}</span></div>Hello World,</div> 
            <h1 className="title"><span>I'm Mike</span></h1> 
            <p className="main-text"><span>I am</span> <TypingText texts={darkMode?[{text: "Jack's broken heart.", gif: jacksHeart}, {text: "the one who knocks!", gif: knocks}, {text: "your father!", gif: father}]:[{text: "a JavaScript software engineer with 8 years of experience."},{text:'a self-motivated and open-minded person who can find creative solutions.'},{text:'a fast-learner and a communicative team player.'}]} darkMode={darkMode}/></p>  
            <div className="buttons"> 
              <Scrollchor to="my-skills" animate={{ duration: 3000, easing: easeOutQuad, offset: scrOffset}}>
                <button  className="button main-button">
                  <span>My Skills</span>
                </button>
              </Scrollchor>
              <Scrollchor to="portfolio" animate={{ duration: 3000, easing: easeOutQuad, offset: scrOffset }}>
                <button className="button secondary-button">
                  <span>Portfolio</span>
                </button>
              </Scrollchor>

            </div>
          </div>
          <div className="hero-right">
            {darkMode?<StaticImage
                src="../images/redmikay.png"
                width={800}
                quality={95}
                placeholder="blurred"
                style={{fill: "red"}}
                formats={["auto", "webp", "avif"]}
                alt="Mikayel Ghukasyan"
              />:
              <StaticImage
                src="../images/mike.png"
                width={600}
                quality={95}
                placeholder="blurred"
                style={{fill: "red"}}
                formats={["auto", "webp", "avif"]}
                alt="Mikayel Ghukasyan"
              />}
          </div>
        </div>
      </div>
    </section>
    <AboutMe darkMode={darkMode}/>
    <MySkills darkMode={darkMode}/>
    <Portfolio darkMode={darkMode}/>
    <WhyMe darkMode={darkMode}/> 
    <Contact darkMode={darkMode}/> 
  </Layout>)}

export default IndexPage
