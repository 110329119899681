import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const AboutMe = ({ darkMode }) => {

  return (<section className={"section about-me " + (darkMode ? " bg-dark dark-mode" : "")} id="about-me" >
    <div className="container">
      <div className="about-me-inner section-inner">
        <div className="about-me-left section-left">
          <p className="section-title">About Me</p>

          <p className="text">
            I am a software engineer with over <b>8 years of experience</b>, having developed a range of web applications from simple landing pages to complex SaaS and e-commerce projects. My experience includes significant roles at <b><a href="https://picsart.com" rel="nofollow" class="simple-link"><StaticImage
              src="../images/picsart.png"
              alt="picsart.com website"
              width={20}
              quality={95}
              placeholder="blurred"
              formats ={["auto", "webp", "avif"]}
              className="inline-image"
               />
              Picsart</a></b> and freelance projects through <b><a href="https://www.toptal.com/resume/mikayel-ghukasyan" rel="nofollow" class="simple-link"><StaticImage
              src="../images/toptal.png"
              alt="toptal.com website"
              width={20}
              quality={95}
              placeholder="blurred"
              formats ={["auto", "webp", "avif"]}
              className="inline-image"
               />
              Toptal</a></b>, where I demonstrated my ability to learn quickly, devise solutions, and work effectively within dynamic teams. I am self-motivated, open-minded, and prefer to take full responsibility for projects, engaging in all aspects of development and deployment to ensure excellence and innovation at every stage.
          </p>
        </div>
        <div className="about-me-right section-right">
          <div className="stat-boxes">
            <div className="stat-box">
              <div className="box-inner">
                <div className="number">8+</div>
                <div className="text">Years of Experience</div>
              </div>
            </div>
            <div className="stat-box">
              <div className="box-inner">
                <div className="number">100+</div>
                <div className="text">Projects</div>
              </div>
            </div>
            <div className="stat-box">
              <div className="box-inner">
                <div className="number">6k+</div>
                <div className="text">Cups of Coffee</div>
              </div>
            </div>
            <div className="stat-box">
              <div className="box-inner">
                <div className="number">50+</div>
                <div className="text">Happy Clients</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}


export default AboutMe
