import React, { useEffect, useState } from "react";

function TypingText({texts, darkMode}) {
    
    let round = 0;
    let text = texts[round].text;
    let limit = 0;
    let [gif,setGif] = useState(texts[round].gif); 
    let [deleting,setDeleting] = useState(false); 
    let [letters,setLetters] = useState(""); 
    let interval = 75;
    let delInterval = 3500;
    let timeout;
    const deleteText = () => {
        setDeleting(false);
      //  console.log('deleting');
        setLetters("");
        round++;
        limit = 0;
        if(round>=texts.length) {
            round = 0;
        }
        text = texts[round].text;
        setGif(texts[round].gif);
        timeout = setTimeout(addLetter,interval * 2);

    };
    const addLetter = () => {
        clearTimeout(timeout);
       // console.log("letters.length = "+letters.length);
      //  console.log("text.length = "+text.length);
        if(limit<text.length) {
            limit++;
       //     console.log('adding '+limit);
            setDeleting(false);
            setLetters(text.substring(0,limit));
         //   console.log(letters);
            timeout = setTimeout(addLetter,interval);
        }
        else {
            setTimeout(() => setDeleting(true), delInterval/2);
            timeout = setTimeout(deleteText, delInterval);
        }
    }

    useEffect(()=>{
        clearTimeout(timeout);
        setDeleting(false);
        setGif(texts[round].gif);

        timeout = setTimeout(addLetter,interval * 5);
        return () => {
            setDeleting(false);
            clearTimeout(timeout);
        } 
    },[darkMode]);
   
    return <><span className={gif?"image-on-hover":""}>{ letters.split(" ").map((l,k) =><><span className={deleting?"typed-letter deleting":"typed-letter"} key={k}>{l}</span> </>) }
    {gif?<img src={gif} alt="Animated Gif" className="hover-img"
                />:null}</span></>
}

export default TypingText