import React from "react"
import { StaticImage } from "gatsby-plugin-image"


const desktopParams= {
  width:600,
  quality:95,
  placeholder:"blur",
  formats: ["auto", "webp", "avif"],
  className: "laptop-image",
  objectPosition: "50% 0"
}

const mobileParams= {
  width:500,
  quality:95,
  placeholder:"blur",
  formats: ["auto", "webp", "avif"],
  className: "phone-image",
  objectPosition: "50% 0"
}

export const desktop1 = <StaticImage
    src="../images/niacom.jpg"
    alt="niacom.us website desktop screenshot"
    {...desktopParams}
/>;
export const desktop2 = <StaticImage
    src="../images/fonter.am.png"
    alt="fonter.am website desktop screenshot"
    {...desktopParams}
/>;
export const desktop3 = <StaticImage
    src="../images/approfessionals-desktop.png"
    alt="approfessionals.com website desktop screenshot"
    {...desktopParams}
/>;
export const desktop4 = <StaticImage
    src="../images/arete-sport.jpg"
    alt="arete-sport.com website desktop screenshot"
    {...desktopParams}
/>;
export const desktop5 = <StaticImage
    src="../images/apprisedmarketing.jpg"
    alt="apprisedmarketing.com website desktop screenshot"
    {...desktopParams}
/>;
export const desktop6 = <StaticImage
    src="../images/andale.jpg"
    alt="andaledepew.com website desktop screenshot"
    {...desktopParams}
/>;
export const desktop7 = <StaticImage
    src="../images/freshwallpaint.jpg"
    alt="freshwallpaint.com website desktop screenshot"
    {...desktopParams}
/>;
export const desktop8 = <StaticImage
    src="../images/largo.jpg"
    alt="largocapital.com website desktop screenshot"
    {...desktopParams}
/>;
export const desktop9 = <StaticImage
    src="../images/missionfirstpartners.jpg"
    alt="missionfirstpartners.net website desktop screenshot"
    {...desktopParams}
/>;
export const desktop10 = <StaticImage
    src="../images/switalabernerconstruction.jpg"
    alt="www.switalabernerconstruction.com website desktop screenshot"
    {...desktopParams}
/>;
export const desktop11 = <StaticImage
    src="../images/nzlabs.jpg"
    alt="nzlabs.io website desktop screenshot"
    {...desktopParams}
/>;
export const desktop12 = <StaticImage
    src="../images/buffalocabinetandcountertops.jpg"
    alt="buffalocabinetandcountertops.com website desktop screenshot"
    {...desktopParams}
/>;
export const desktop13 = <StaticImage
    src="../images/buffalosbestflooring.jpg"
    alt="buffalosbestflooring.com website desktop screenshot"
    {...desktopParams}
/>;
export const desktop14 = <StaticImage
    src="../images/jmsurfacesolutions.jpg"
    alt="www.jmsurfacesolutions.com website desktop screenshot"
    {...desktopParams}
/>;
export const mobile1 = <StaticImage
  src="../images/niacom-mobile.jpg"
  alt="niacom.us website mobile screenshot"
  {...mobileParams}
/>;
export const mobile2 = <StaticImage
    src="../images/fonter-mobile.png" 
    alt="fonter.am website mobile screenshot"
    {...mobileParams}
/>;
export const mobile3 = <StaticImage
    src="../images/approfessionals-mobile.jpg"
    alt="approfessionals.com website mobile screenshot"
    {...mobileParams}
/>;
export const mobile4 = <StaticImage
    src="../images/arete-sport-mobile.jpg"
    alt="arete-sport.com website mobile screenshot"
    {...mobileParams}
/>;
export const mobile5 = <StaticImage
    src="../images/apprisedmarketing-mobile.jpg"
    alt="apprisedmarketing.com website mobile screenshot"
    {...mobileParams}
/>;
export const mobile6 = <StaticImage
    src="../images/andale-mobile.jpg"
    alt="andaledepew.com website mobile screenshot"
    {...mobileParams}
/>;
export const mobile7 = <StaticImage
    src="../images/freshwallpaint-mobile.jpg"
    alt="freshwallpaint.com website mobile screenshot"
    {...mobileParams}
/>;
export const mobile8 = <StaticImage
    src="../images/largo-mobile.jpg"
    alt="largocapital.com website mobile screenshot"
    {...mobileParams}
/>;
export const mobile9 = <StaticImage
    src="../images/missionfirstpartners-mobile.jpg"
    alt="missionfirstpartners.net website mobile screenshot"
    {...mobileParams}
/>;
export const mobile10 = <StaticImage
    src="../images/switalabernerconstruction-mobile.jpg"
    alt="www.switalabernerconstruction.com website mobile screenshot"
    {...mobileParams}
/>;
export const mobile11 = <StaticImage
    src="../images/nzlabs-mobile.jpg"
    alt="nzlabs.io website mobile screenshot"
    {...mobileParams}
/>;
export const mobile12 = <StaticImage
    src="../images/buffalocabinetandcountertops-mobile.jpg"
    alt="buffalocabinetandcountertops.com website mobile screenshot"
    {...mobileParams}
/>;
export const mobile13 = <StaticImage
    src="../images/buffalosbestflooring-mobile.jpg"
    alt="buffalosbestflooring.com website mobile screenshot"
    {...mobileParams}
/>;
export const mobile14 = <StaticImage
    src="../images/jmsurfacesolutions-mobile.jpg"
    alt="www.jmsurfacesolutions.com website mobile screenshot"
    {...mobileParams}
/>;
