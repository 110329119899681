import React, {useState} from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useRecoilState } from "recoil";
import {darkModeState} from './state';
import { StaticImage, GatsbyImage  } from "gatsby-plugin-image"



const PortfolioItem = (props) => {
  let [opened,setOpened] = useState(false)
  const openPort = () => {
    setOpened(true);
  };
  let logo = "../images/fonter.am-logo.png";
  return (   <>
          <a href={props.link} target="_blank" rel="nofollow" className={"portfolio-grid-item" + (props.isLeft?" portfolio-grid-item-left":"")}>
            <div className="portfolio-grid-item-inner">
              <div className="text">
                <div className="title">
                    {props.title}
                </div>
                <div className="subtitle">{props.description}</div>
              </div>
              <div className="images">
                <div className="laptop"> 
                  <StaticImage 
                    src="../images/desktop-screen.png"
                    width={500}
                    quality={95}
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="Laptop"
                    className="laptop-device"
                  />
                  {props.desktop}

                </div>
                <div className="phone">
                  <StaticImage
                    src="../images/phone-screen.png"
                    width={600}
                    quality={95}
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="Smartphone"
                    className="phone-device"
                  />
                  {props.mobile}

                </div>
                
              </div>
            </div>
          </a></> )}


export default PortfolioItem
