import React, {useState} from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
//import { useRecoilState } from "recoil";
//import {darkModeState} from './state';
import { StaticImage } from "gatsby-plugin-image"
import PortfolioItem from "./portfolio-item";
import * as images from "./images";

const Portfolio = ({darkMode}) => {
  // let [darkMode, setDarkMode] = useRecoilState(darkModeState);
  let portfolios = [
    {
      id:1,
      title: "Fonter.am",
      description: "Personal Project: Free Armenian Fonts website",
      desktop: images.desktop2,
      mobile: images.mobile2,
      isLeft: true,
      link: "https://fonter.am",
    },
    {
      id:2,
      title: "AP Professionals",
      description: "Next.js website for a US staffing agency",
      desktop: images.desktop3,
      mobile: images.mobile3,
      link: "https://approfessionals.com",
    },
    {
      id:3,
      title: "Apprised Marketing",
      description: "Next.js website with a dashboard for a US Marketing agency.",
      desktop: images.desktop5,
      mobile: images.mobile5,
      link: "https://apprisedmarketing.com",

    },
    {
      id:4,
      title: "Niacom",
      description: "React website for a US Fiber-Optic internet provider",
      desktop: images.desktop1,
      mobile: images.mobile1,
      isLeft: true,
      link: "https://niacom.us",
    },
    {
      id:5,
      title: "Arete Sport",
      description: "React (Next.js) Website for a sports management company.",
      desktop: images.desktop4,
      mobile: images.mobile4,
      link: "https://arete-sport.com",

    },

    {
      id:6,
      title: "Largo Capital",
      description: "WordPress site for a real estate financing company.",
      desktop: images.desktop8,
      mobile: images.mobile8,
      link: "https://largocapital.com",

    },
    {
      id:7,
      title: "FreshWallPaint",
      description: "WordPress website for a painting company.",
      desktop: images.desktop7,
      mobile: images.mobile7,
      link: "https://www.freshwallpaint.com",
      isLeft: true,

    },


    {
      id:8,
      title: "Andale Depew",
      description: "React website for a mexican restaurant in New York.",
      desktop: images.desktop6,
      mobile: images.mobile6,
      isLeft: true,
      link: "https://andaledepew.com",

    },

    {
      id:9,
      title: "Mission First Partners",
      description: "Charity website on WordPress",
      desktop: images.desktop9,
      mobile: images.mobile9,
      link: "https://missionfirstpartners.net",
      isLeft: true,

    },

    {
      id:10,
      title: "Switala Berner Construction",
      description: "WordPress site for a roofing company.",
      desktop: images.desktop10,
      mobile: images.mobile10,
      link: "https://www.switalabernerconstruction.com",

    },

    {
      id:11,
      title: "NZ Labs", 
      description: "Next.js website for an IT outsorcing agency",
      desktop: images.desktop11,
      mobile: images.mobile11,
      link: "https://nzlabs.io",
      isLeft: true,

    },

    {
      id:12,
      title: "Buffalo Cabinet and Countertops",
      description: "Cabinet Refinishing company site.",
      desktop: images.desktop12,
      mobile: images.mobile12,
      link: "https://buffalocabinetandcountertops.com",

    },

    {
      id:13,
      title: "JM Surface Solutions",
      description: "WordPress site for a power washing company.",
      desktop: images.desktop14,
      mobile: images.mobile14,
      link: "https://www.jmsurfacesolutions.com",

    },



  ]
  let [limit, setLimit] = useState(4);

   

  return (    <section className={"section portfolio " + (darkMode?" bg-dark dark-mode":"")}  id="portfolio">
  <div className="container">
    <div className="section-inner">
      <div className="section-left">
        <p className="section-title">Portfolio</p>
        <p className="text">Some of the projects I created apart from my roles at <b><a href="https://picsart.com" rel="nofollow noreferrer noopener" className="simple-link"><StaticImage
              src="../images/picsart.png"
              alt="picsart.com website"
              width={20}
              quality={95}
              placeholder="blurred"
              formats ={["auto", "webp", "avif"]}
              className="inline-image"
               />
              Picsart</a></b> and freelance projects through <b><a href="https://www.toptal.com/resume/mikayel-ghukasyan" rel="nofollow noreferrer noopener" className="simple-link"><StaticImage
              src="../images/toptal.png"
              alt="toptal.com website"
              width={20}
              quality={95}
              placeholder="blurred"
              formats ={["auto", "webp", "avif"]}
              className="inline-image"
               />
              Toptal</a></b></p>
      </div>
    </div>
    <div className="portfolio-inner">
        <div className="portfolio-grid">
          {portfolios.slice(0,limit).map((item, i) => 
          (<PortfolioItem key={item.id} {...item} isLeft={i % 2 === 0}/>))}
       </div>
       {limit<portfolios.length?<div className="text-center">
        <button className="button main-button" onClick={()=>setLimit(limit+2)}><span>Show More</span></button>
       </div>:null}
    </div>
  </div>
</section>
)}


export default Portfolio
