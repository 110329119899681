import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
//import { useRecoilState } from "recoil";
//import {darkModeState} from './state';
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAws, faBootstrap, faCss3, faCss3Alt, faEnvira, faGit, faGitAlt, faGoogle, faHtml5, faJs, faJsSquare, faLaravel, faNode, faNodeJs, faPhp, faReact, faReacteurope, faSass, faTypo3, faVuejs, faAndroid } from '@fortawesome/free-brands-svg-icons'
import { faDatabase, faFire, faFireAlt, faServer, faSoap, faTools } from '@fortawesome/free-solid-svg-icons'
import liamGif from '../gifs/liamneeson.gif';
import { faTypeScript } from "../svg/faTypeScript"
 
const MySkills = ({darkMode}) => { 
 // let [darkMode, setDarkMode] = useRecoilState(darkModeState);
  let skills = [
    {
      title: "Frontend",
      list: [
        <><FontAwesomeIcon icon={ faTypeScript } /> TypeScript </>, 
        <><FontAwesomeIcon icon={ faJs } /> JavaScript (ES6+)</>, 
        <><FontAwesomeIcon icon={ faReact } /> React.js</>, 
        <><FontAwesomeIcon icon={ faReacteurope } /> Redux</>, 
        <><FontAwesomeIcon icon={ faReact } /> Next.js</>, 
        <><FontAwesomeIcon icon={ faReact } /> Gatsby</>, 
        <><FontAwesomeIcon icon={ faHtml5 } /> HTML5</>, 
        <><FontAwesomeIcon icon={ faCss3Alt } /> CSS3</>,  
        <><FontAwesomeIcon icon={ faSass } /> SASS/SCSS</>,  
        <><FontAwesomeIcon icon={ faCss3 } /> ChakraUI</>, 
        <><FontAwesomeIcon icon={ faBootstrap } /> Bootstrap</>,  
        <><FontAwesomeIcon icon={ faCss3 } /> Tailwind</>, 
      ] 
    },
    {
      title: "Backend",
      list: [
        <><FontAwesomeIcon icon={ faNodeJs } /> Node.JS</>, 
        <><FontAwesomeIcon icon={ faNodeJs } /> Express JS</>, 
        <><FontAwesomeIcon icon={ faServer } /> Strapi</>, 
        <><FontAwesomeIcon icon={ faPhp } /> PHP</>, 
        <><FontAwesomeIcon icon={ faDatabase } /> MySQL</>,
        <><FontAwesomeIcon icon={ faFire } /> Codeigniter</>, 
        <><FontAwesomeIcon icon={ faEnvira } /> MongoDB</>,  
        <><FontAwesomeIcon icon={ faServer } /> REST API</>, 
        <><FontAwesomeIcon icon={ faDatabase } /> GraphQL</>, 
      ] 
    },
    {
      title: "Tools",
      list: [
        <><FontAwesomeIcon icon={ faGitAlt } /> git</>, 
        <><FontAwesomeIcon icon={ faTools } /> Jest</>, 
        <><FontAwesomeIcon icon={ faTools } /> Webpack</>, 
        <><FontAwesomeIcon icon={ faTools } /> Gulp</>, 
        <><FontAwesomeIcon icon={ faTools } /> Lighthouse</>, 
      ] 
    },
    {
      title: "Working Experience With",
      list: [
        <><FontAwesomeIcon icon={ faFireAlt } /> Firebase</>, 
        <><FontAwesomeIcon icon={ faLaravel } /> Laravel</>, 
        <><FontAwesomeIcon icon={ faDatabase } /> SOAP</>, 
        <><FontAwesomeIcon icon={ faTools } /> Grunt</>, 
        <><FontAwesomeIcon icon={ faGoogle } /> GCP</>, 
        <><FontAwesomeIcon icon={ faAws } /> AWS</>, 
        <><FontAwesomeIcon icon={ faAndroid } /> Kotlin</>, 
      ] 
    },
  ]
  return (<section className={"section my-skills " + (darkMode?" bg-dark-light dark-mode":"bg-light")} id="my-skills" >
  <div className="container"> 
    <div className="my-skills-inner section-inner">
      <div className="my-skills-left section-left">
        <p className="section-title">My Skills</p>
        <p className="text">
          I have <span className="image-on-hover">a very particular set of skills <img
                  src={liamGif}
                  alt="Liam Neeson - Taken"
                  className="hover-img"
                /> </span>, skills I have acquired over a very long career.
        </p>
        {
          skills.map(skill => <div className="skills-block">
            <div className="skills-block-title">
              {skill.title}
            </div>
            <ul className="skills-list">{skill.list.map(item => <li>{item}</li>)}</ul>
          </div>)
        }
      </div>
      <div className="my-skills-right section-right">
        <div className="my-skills-image-wrapper">
          <div className="skill-logo skill-logo-1">
            <StaticImage
                  src="../images/js.png"
                  width={80}
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="React js"
                  className="skill-logo-img "
                  transformOptions = {{fit: "cover"}}
                />
          </div>
          <div className="skill-logo skill-logo-2">
            <StaticImage
                  src="../images/react.png"
                  width={80}
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="React"
                  className="skill-logo-img "
                  transformOptions = {{fit: "cover"}}
                />
          </div>
          <div className="skill-logo skill-logo-3">
            <StaticImage
                  src="../images/stackoverflow.png"
                  width={80}
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="StackOverflow"
                  className="skill-logo-img "
                  transformOptions = {{fit: "cover"}}
                />
          </div> 
          <div className="skill-logo skill-logo-4">
            <StaticImage
                  src="../images/nodejs.png"
                  width={80}
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="React js"
                  className="skill-logo-img "
                  transformOptions = {{fit: "cover"}}
                /> 
          </div>
          <div className="skill-logo skill-logo-5">
            <StaticImage
                  src="../images/php.png"
                  width={80}
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="PHP"
                  className="skill-logo-img "
                  transformOptions = {{fit: "cover"}}
                />
          </div>
          <StaticImage
                src="../images/skills.png" 
                width={600}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Mikayel Ghukasyan"
                className="my-skills-image"
                transformOptions = {{fit: "cover"}}
              />
          <StaticImage
                src="../images/skills-alt.png" 
                width={600}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Mikayel Ghukasyan"
                className="my-skills-image-alt"
                transformOptions = {{fit: "cover"}}
              />

        </div>
      </div>
    </div>
  </div>
</section>
)}


export default MySkills
