import { faChevronRight, faEnvelope, faMapMarker, faPhoneAlt, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Field, Form } from "formik";
import React, { useState } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import { faFacebook, faFacebookF, faLinkedin, faTelegram, faTelegramPlane, faTwitter } from '@fortawesome/free-brands-svg-icons';
const API_PATH = '/contact.php';

const Contact = ({darkMode}) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [mailError, setMailError] = useState();

  const ValidationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(2, 'This Field is Too Short!')
      .max(100, 'This Field is Too Long!')
      .required('This Field is Required'),
    lastname: Yup.string()
      .min(2, 'This Field is Too Short!')
      .max(100, 'This Field is Too Long!')
      .required('This Field is Required'),
    email: Yup.string().email('Invalid email').required('This Field is Required'),
    phonenumber: Yup.string(),
    message: Yup.string().min(2, 'This Field is Too Short!')
    .required('This Field is Required'),
  });


  

  return (<section className={"section contact " + (darkMode?" bg-dark-light dark-mode":"bg-light")} id="contact" >
  <div className="container"> 
         <div className="contact-inner section-inner">

          <div className="contact-left section-left">
              <p className="section-title">Get in Touch</p>
              <p className="text">Use the contact or fill out the form to get in touch with me.</p>
              <a href="tel:+37493603903" className="icon-box-left">
                <div className="icon">
                  <FontAwesomeIcon icon={faPhoneAlt}/>
                </div>
                <div className="text">093-603-903</div>
              </a>
              <a href="mailto:hey@mik.am" className="icon-box-left">
                <div className="icon">
                  <FontAwesomeIcon icon={faEnvelope}/>
                </div>
                <div className="text">hey@mik.am</div>
              </a>
              <a href="https://www.linkedin.com/in/michaelghukasyan/" className="social-icon">
                  <FontAwesomeIcon icon={faLinkedin}/>
              </a>
              <a href="https://t.me/redmikay" className="social-icon">
                  <FontAwesomeIcon icon={faTelegramPlane}/>
              </a>
              <a href="https://www.facebook.com/michaelghukasyan/" className="social-icon">
                  <FontAwesomeIcon icon={faFacebookF}/>
              </a>
              <a href="https://twitter.com/redmikay" className="social-icon">
                  <FontAwesomeIcon icon={faTwitter}/>
              </a>
          </div>    
          <div className="contact-right section-right">
            <div className="inner" data-aos="fade-up">
            <div className="form-wrapper">
            {mailSent ? 
                  <div className="text-center">
                    <div className="success-box" role="alert">
                    <p className="h1">Thank You</p>
                    Your message has been sent successfully! I will get back to you soon.
                    </div>  
                  </div>
                : <Formik
                initialValues={{ firstname:"",lastname:"",message:"",email:"",phonenumber:"" }}
                validationSchema={ValidationSchema}

                onSubmit={async (values) => { 

                 await axios({
                    method: 'post',
                    url: `${API_PATH}`,
                    headers: { 'content-type': 'application/json' },
                    data: values
                  })
                    .then(result => { 

                      setMailSent(result.data.sent)
                      if(!result.data.sent) {
                        setMailError(result.data.message);
                      }
                      else {
                        setMailError(false);
                      }
                    })
                    .catch(error => setMailError(error.message ));
  
              }}
              >
              {({isSubmitting}) => (
              <Form> 
                {isSubmitting ? <div className="loading"></div>:""}
                <div className="form-row"> 
                  <div className="half-col">
                    <Field id="firstname" name="firstname">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <div>
                          <input type="text" placeholder="First Name" {...field} 
                          invalid={(meta.touched || errors) && meta.error} 
                          valid={meta.touched && !meta.error} />
                          {touched && meta.error && (
                            <div className="form-feedback">{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>
                    
                  </div>
                  <div className="half-col">
                    <Field id="lastname" name="lastname">
                        {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta,
                        }) => (
                          <div>
                            <input type="text" placeholder="Last Name" {...field} 
                            invalid={(meta.touched || errors) && meta.error} 

                            valid={meta.touched && !meta.error} />
                            {meta.touched && meta.error && (
                              <div className="form-feedback">{meta.error}</div>
                            )}
                          </div>
                        )}
                    </Field>
                  </div>
                </div>
                <div className="form-row"> 
                  <div lg="6" className="mb-3">
                    <Field id="email" name="email">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <div>
                          <input type="email" placeholder="Your Email" {...field} 
                          invalid={(meta.touched || errors) && meta.error} 
                          valid={meta.touched && !meta.error} />
                          {meta.touched && meta.error && (
                            <div className="form-feedback">{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div lg="6" className="mb-3">
                    <Field id="phonenumber" name="phonenumber">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <div>
                          <input type="tel" placeholder="Your Phone" {...field} 
                          invalid={(meta.touched || errors) && meta.error} 
                          valid={meta.touched && !meta.error} />
                          {meta.touched && meta.error && (
                            <div className="form-feedback">{meta.error}</div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
{/*                     <Field id="topic" name="topic">
                        {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta,
                        }) => (
                          <div>
                            <input type="text" placeholder="Question Topic" {...field} 
                            invalid={(meta.touched || errors) && meta.error} 

                            valid={meta.touched && !meta.error} />
                            {meta.touched && meta.error && (
                              <div className="form-feedback">{meta.error}</div>
                            )}
                          </div>
                        )}
                    </Field>
 */}                    <Field id="message" name="message">
                        {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta,
                        }) => (
                          <div>
                            <textarea type="textarea" placeholder="Enter Your Message" {...field} 
                            invalid={(meta.touched || errors) && meta.error} 
                            valid={meta.touched && !meta.error} />
                            {meta.touched && meta.error && (
                              <div className="form-feedback">{meta.error}</div>
                            )}
                          </div>
                        )}
                    </Field>
                <button className="button main-button" disabled={isSubmitting}  type="submit">
                  <span>Submit</span>
                </button>
                {
                mailError ? 
                <div className="text-center">
                  <div className="error-box" role="alert">
                  <p className="h1">Error</p>
                  {mailError}
                  </div>  
                </div> : ""
              }
 
              </Form>)}
              </Formik> }
            </div>
          </div>

          </div>
          </div>
        </div>
    </section>
)
}
export default Contact
